@import "src/styles/layout";
@import "src/styles/media";

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: firebrick;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  padding: 20px;
  box-sizing: border-box;
}

.footer-container .main-section {
  display: flex;
  margin-right: 20px;
}

.footer-container .main-section .cro-chequy {
  margin: 4px 20px 0 0;
}

.footer-container .legal-section {
}

.footer-container .footer-column {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.footer-container .footer-link {
  display: inline;
  text-decoration: none;
  color: inherit;
}

.footer-link + .footer-link {
  margin-top: 5px;
}

.footer-container .footer-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1100px) {
  .footer-container {
    // height: calc($footer-height + 20px);
  }
}

@media screen and (max-width: 990px) {
  .footer-container {
    padding: 10px;
  }
  .footer-container .legal-section {
    display: none;
  }

  .footer-container .main-section {
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .footer-container {
    // height: calc($footer-height + 50px);
  }
}
