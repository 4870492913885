@import "src/styles/layout";
@import "src/styles/media";

.navbar {
  width: 70%;
  height: $navbar-height;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 5px -4px black;
  display: flex;
  flex-flow: row nowrap;
  z-index: 1;
}

.navbar .cro-chequy {
  position: relative;
  top: 0;
  height: 100%;
  display: float;
  float: left;
}

@media screen and (max-width: $md-max) {
}

@media screen and (max-width: $sm-max) {
  .navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
