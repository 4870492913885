@import "src/styles/layout";
@import "src/styles/media";

.home-container {
  padding: $cc-padding;
}

.home-text {
  margin: 20px 10px;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

.home-container .home-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container .home-gallery img {
  width: 50%;
}

@media screen and (max-width: $md-max) {
  .home-gallery {
    flex-direction: column;
  }

  .home-container .home-gallery img {
    width: 100%;
  }
}

@media screen and (max-width: $sm-max) {
  .home-text {
    font-size: 25px;
  }
}
