@import "src/styles/layout";
@import "src/styles/media";

#root {
  width: 70%;
  margin: auto;
  background-color: white;
  position: relative;
  min-height: 100vh;
}

.content-container {
  padding-top: $navbar-height;
  padding-bottom: $footer-height;
}

@media screen and (min-width: $bg-min) {
}

@media screen and (max-width: $md-max) {
}

@media screen and (max-width: $sm-max) {
  #root {
    width: 100%;
  }
}
